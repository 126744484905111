quick-chat {
    z-index: 399;

    > div {
        overflow: hidden;
    }

    &.quick-chat-opened {

        > div {
            overflow: visible;
        }
    }

    &:not(.quick-chat-opened) {

        > div {
            overflow: visible;
            animation: addOverflowHidden 1ms linear 400ms;
            animation-fill-mode: forwards;
        }
    }
}

/* Adjustments depending on the selected layout */
.quick-chat-header {
    height: 64px;

    enterprise-layout &,
    modern-layout & {
        height: 80px !important;
    }
}


/* Overlay */
.quick-chat-overlay {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 299;
    opacity: 1;
    background-color: transparent;
}

@keyframes addOverflowHidden {
    0% {
        overflow: visible
    }
    99% {
        overflow: visible;
    }
    100% {
        overflow: hidden;
    }
}
